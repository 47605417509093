
export class AppConstants {

    public static NOTIFICATION_CREATE_SUCCESS = "Creation enregistre avec succes";
    public static NOTIFICATION_UPDATE_SUCCESS = "Modification enregistree avec succes";
    public static NOTIFICATION_DELETE_SUCCESS = "Suppression enregistree avec succes";

    public static NOTIFICATION_CREATE_ERROR = "La creation n'a pas pu etre effectue";
    public static NOTIFICATION_UPDATE_ERROR = "La modification n'a pas pu etre effectuee";
    public static NOTIFICATION_DELETE_ERROR = "La suppression n'a pas pu etre effectuee";

    public static PAYMENT_SUCCESS = "Paiement enregistre avec succes";
    public static PAYMENT_ERROR = "Le paiement n'a pas pu etre effectue";
}