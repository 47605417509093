import { Cart } from '../../models/cart';
import { Account } from '../../models/account';
import { ISignup } from '../../models/signup';
import { IStudentRequest } from "@app/student/student.interface";

export class CartStateModel {
    cart?: Cart;
    accounts?: Account[];
    links?: any;
    duplicateError?: any;
    cgu?: any;
    invitation?: any;
}

export class PaymentStateModel {
    payment?: any;
    id?: any;
    payment_method?: any;
    card_uri?: any;
    postfinance_data?: any;
    target?: any;
    fraudscore?: any;
    deferred_invoice?: boolean;
    set_default?: boolean;
    selected_payment_is_default?: boolean;
}

export class SignupStateModel {
    signup: ISignup;
}

export class StudentStateModel {
    studentRequest: IStudentRequest;
}

export class SuggestionsModel {
    suggestions?: {
      true?: DomainSuggestionModel;
      false?: DomainSuggestionModel;
    };
    tlds?: any[];
    categories_tlds?: any[];
}

export class DomainSuggestionModel {
    domains: {};
}

export const responseType = '?with=items';
