<mat-dialog-content>
    <section>
        <div>
            <p class="mat-dialog-content__message">
                {{
                    'Votre commande sera traitee immediatement et ce paiement sera ajoute a votre facture a payer en fin de mois.'
                        | transloco
                }}
            </p>
            <p class="mat-dialog-content__message">
                {{ 'Souhaitez-vous quand meme utiliser ce mode de paiement ?' | transloco }}
            </p>
        </div>
    </section>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="block-buttons clearfix">
        <button (click)="save()" *ngIf="!loading" color="primary" mat-flat-button type="submit">
            {{ 'CONTINUER' | transloco }}
        </button>
        <button *ngIf="loading" color="primary" mat-flat-button>
            <div class="loader__anim bg-blue"></div>
            <div class="loader__anim bg-blue"></div>
            <div class="loader__anim bg-blue"></div>
        </button>
        <button (click)="close()" [disabled]="loading" color="accent" mat-flat-button matDialogClose>
            {{ 'CHOISIR UN AUTRE MODE DE PAIEMENT' | transloco }}
        </button>
    </div>
</mat-dialog-actions>
<ng-template #buttonLoader></ng-template>
