import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoaderService {

  public item = {
    isLoading$: null,
    set: (boolean) => {
    },
    get: () => {
    },
  };

  public isLoading$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.item.isLoading$ = new BehaviorSubject<boolean>(false);
    this.item.set = (state: boolean) => {
      this.item.isLoading$.next(state);
    };
    this.item.get = () => {
      return this.item.isLoading$;
    };
  }

  get loading() {
    return this.isLoading$;
  }

  set(state: boolean) {
    this.isLoading$.next(state);
  }
}
