import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { InitCart } from '@app/store/actions/cart.action';
import { CartState } from '@app/store/state/cart.state';

@Injectable()
export class ProfileResolve implements Resolve<any> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const params = ['ksuite'];

    if (window?.['CURRENT_ORDER_SERVICE'] === 'llm') {
      params.push('ai-tools');
    }
    this.store.dispatch(new InitCart(params.join(',')));
    return this.store.selectOnce(CartState.getCart);
  }
}
