export class PaymentCache {
    static readonly type = '[PAYMENT] Get Cache';

    constructor() { }
}

export class UpdatePaymentCache {
    static readonly type = '[PAYMENT] Update Cache';

    constructor() { }
}

export class PaymentCheckout {
    static readonly type = '[PAYMENT] Checkout';

    constructor() { }
}

export class PaymentTarget {
    static readonly type = '[PAYMENT] Set Target';

    constructor(public target?: string, public id?: string, public payment_method?: string, public selected_payment_is_default?: boolean) { }
}

export class DeferredInvoice {
    static readonly type = '[PAYMENT] Set Deferred Invoice';

    constructor(public value: boolean) { }
}

export class CardToken {
    static readonly type = '[PAYMENT] Save Card Token';

    constructor(public payload: any) { }
}

export class CreateAddress {
    static readonly type = '[PAYMENT] Create Facturation Address';

    constructor(
        public payload: {
            type: string;
            organization_name: string;
            street: string;
            street_complement: string;
            vat_number: string;
            zip_code: string;
            city: string;
            default: boolean;
        }
    ) { }
}
export class UpdateAddress {
    static readonly type = '[PAYMENT] Update Address';

    constructor(
        public payload: {
            type: string;
            organization_name: string;
            street: string;
            street_complement: string;
            vat_number: string;
            zip_code: string;
            city: string;
            default: boolean;
        },
        public id
    ) { }
}
export class SetAddress {
    static readonly type = '[PAYMENT] Set Facturation Address';

    constructor(public payload: number) { }
}

export class SetPrepraidComplement {
    static readonly type = '[PAYMENT] Set Prepaid Complement';

    constructor(public payload: boolean) { }
}

export class SetRenewal {
    static readonly type = '[PAYMENT] Set (optional) Renewal Value';

    constructor(public payload: boolean) { }
}

export class SetDefaultProfilePayment {
    static readonly type = '[PAYMENT] Set (optional) Default Profile Payment Value';

    constructor(public payload: boolean) { }
}

export class SetAddingNewCard {
    static readonly type = '[PAYMENT] Toggle card form';

    constructor(public payload: boolean) { }
}

export class GetFraudScore {
    static readonly type = '[FRAUDSCORE] Get FraudScore Datas';

    constructor() { }
}


export class DenyIban {
    static readonly type = '[IBAN] Deny';

    constructor() { }
}

export class Checkout3DS {
    static readonly type = '[CHECKOUT 3D]';

    constructor(public payload: boolean) { }
}

export class PaymentRedirect {
  static readonly type = '[PAYMENT] External redirect';

  constructor(public readonly url: string) {
  }
}

export class ClearPaymentCache {
    static readonly type = '[PAYMENT] Clear Cache';

    constructor() { }
}

