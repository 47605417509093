<mat-dialog-content>
    <ik-alert [description]="'En changeant de compte client vous devrez recommencer votre commande. Le panier en cours sera enregistre sur le compte client $compte$.' | transloco:{compte: account}"
              [type]="'warning'"></ik-alert>
    <p class="no-margin mat-dialog-content__message">{{'Souhaitez-vous vraiment changer de compte client ?' | transloco}}</p>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="block-buttons clearfix">
        <button matDialogClose
                type="submit"
                mat-flat-button
                color="primary"
                (click)="save()"
                class="k-modal__actions__submit">{{'CHANGER DE COMPTE CLIENT' | transloco }}</button>
        <button matDialogClose
                mat-flat-button
                color="accent"
                (click)="close()"
                class="k-modal__actions__cancel">{{ 'ANNULER' | transloco }}</button>
    </div>
</mat-dialog-actions>
