export class SwitchAccount {
  static readonly type = '[ACCOUNT] Switch';

  constructor(public payload: { account_id: string; context?: string, client_id?: string }) {
  }
}

export class SwitchAccountDenied {
  static readonly type = '[ACCOUNT] Switch Denied';

  constructor() {
  }
}

export class CheckAccount {
  static readonly type = '[ACCOUNT] Check';

  constructor(public payload: { email: string }) {
  }
}

export class CreateAccount {
  static readonly type = '[ACCOUNT] Create Account';

  constructor(public payload: any, public force = false) {
  }
}

export class CreateUserAndAccount {
  static readonly type = '[ACCOUNT] Create User And Account';

  constructor(public payload: any, public force = false) {
  }
}

export class Login {
  static readonly type = '[ACCOUNT] Login';

  constructor(public payload: { email: string }) {
  }
}

export class AutoLogin {
  static readonly type = '[ACCOUNT] autoLogin';

  constructor(
    public payload: {
      url: string;
      redirect: string;
      user: string;
      password: string;
    }
  ) {
  }
}
