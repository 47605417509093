import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { VatSwitchToggle } from '@app/store/actions/cart.action';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatomoTrackerService } from '@app/core/matomo-tracker.service';

@Component({
  selector: 'vat-switch',
  templateUrl: './vat-switch.component.html',
  styleUrls: ['./vat-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VatSwitchComponent implements OnInit, OnDestroy {
  @Input() config: {
    incl_vat: boolean,
    force_excluded_vat: boolean,
    disabled: boolean,
    tooltip: string,
    country_has_vat: boolean
  };
  inclVat: 'true' | 'false';
  orderState = window['CURRENT_STATE'].includes('order');
  isMobile = false;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    public breakpointObserver: BreakpointObserver,
    private store: Store,
    private action$: Actions,
    private cdr: ChangeDetectorRef,
    private matomoTracker: MatomoTrackerService
  ) {

    this.config = {
      incl_vat: true,
      force_excluded_vat: false,
      disabled: false,
      tooltip: '',
      country_has_vat: true
    };

    if (localStorage?.getItem) {
      this.inclVat = localStorage.getItem('incl_vat') === 'true' ? 'true' : 'false';
    }
  }

  ngOnInit(): void {
    this.action$.pipe(ofActionDispatched(VatSwitchToggle)).pipe(takeUntil(this.onDestroy$)).subscribe(({ inclVat, markForCheck }) => {
      this.inclVat = inclVat;

      if (localStorage.setItem) {
        localStorage.setItem('incl_vat', String(inclVat));
      }
      // Used for payment page because it doesn't update if we dispatch the action in ngOnInit()
      if (markForCheck) {
        this.cdr.markForCheck();
      }
    });

    this.inclVat = this.inclVat === 'true' && !this.config.force_excluded_vat ? 'true' : 'false';

    this.breakpointObserver.observe([
      '(max-width: 1200px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  toggleTaxMode() {
    this.matomoTracker.trackClick('button_toggle-tax-mode');
    if (localStorage) {
      this.store.dispatch(new VatSwitchToggle(localStorage.getItem('incl_vat') === 'true' ? 'false' : 'true'));
    }
  }

}
