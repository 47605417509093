import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "@app/shared";
import {LoaderModule} from "@infomaniak/angular-common";
import {HeaderTitleModule} from "@infomaniak/ngx-header-title";
import {TranslocoModule} from "@ngneat/transloco";
import {SharedHeaderComponent} from "@app/shared/header/header.component";
import {StoreModule} from "@app/store/store.module";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule} from "@angular/forms";

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [SharedHeaderComponent],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    TranslocoModule,
    LoaderModule,
    HeaderTitleModule,
    StoreModule,
    MatSelectModule
  ],
  exports: [SharedHeaderComponent],
})
export class SharedHeaderModule {
}