import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { Logger } from '../logger.service';
import { AuthenticationService } from './authentication.service';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return true; // @todo middleware.

        // if (this.authenticationService.isAuthenticated()) {
        //     return true;
        // }

        // log.debug('Not authenticated, redirecting and adding redirect url...');
        // this.router.navigate(['/login'], {
        //     queryParams: { redirect: state.url },
        //     replaceUrl: true
        // });
        // return false;
    }
}
