import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AuthenticationService, I18nService, Logger } from '..';
import { environment } from '../../../environments/environment';

const log = new Logger('Login');

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    version: string = environment.version;
    error: string;
    loginForm: FormGroup;
    isLoading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private i18nService: I18nService,
        private authenticationService: AuthenticationService
    ) {
        this.createForm();
    }

    ngOnInit() { }

    login() {
        this.isLoading = true;
        this.authenticationService
            .login(this.loginForm.value)
            .pipe(
                finalize(() => {
                    this.loginForm.markAsPristine();
                    this.isLoading = false;
                })
            )
            .subscribe(
                credentials => {
                    this.route.queryParams.subscribe(params =>
                        this.router.navigate([params.redirect || '/'], {
                            replaceUrl: true
                        })
                    );
                },
                error => {
                    log.debug(`Login error: ${error}`);
                    this.error = error;
                }
            );
    }

    setLanguage(language: string) {
        this.i18nService.setLanguage(language);
    }

    get currentLanguage(): string {
        return this.i18nService.language;
    }

    get languages(): string[] {
        return this.i18nService.supportedLanguages;
    }

    private createForm() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            remember: true
        });
    }
}
