import { Cart } from '@app/models/cart';
import { Item } from '@app/models/item';

import { SwissBackup } from '../../models/items/swiss_backup';

// ____________ CART ______________

export class GetCart {
    static readonly type = '[CART] Get';

    constructor(public payload: Cart) { }
}

export class UpdateCart {
    static readonly type = '[CART] Update';

    constructor(public payload: Cart) { }
}

export class InitCart {
    static readonly type = '[CART] Init';

    constructor(public withCart?: boolean | string, public forceRefresh?: boolean, public forceProductNumber?: boolean) { }
}

export class SetPromo {
    static readonly type = '[CART] Set promo code';

    constructor(public payload: string) { }
}
export class DeletePromo {
    static readonly type = '[CART] Delete promo code';

    constructor(public payload: any) { }
}

export class UpdatePromo {
    static readonly type = '[CART] Update promo code';

    constructor(public discountId: number, public payload: any) { }
}

export class UpdateCartEmail {
    static readonly type = '[CART] Set Email';

    constructor(public payload: string) { }
}

// ____________ FIN_CART ______________

// ____________ ITEM ______________

export class GetItem {
    static readonly type = '[ITEM] Get';

    constructor(public payload: Item) { }
}

export class AddItem {
    static readonly type = '[ITEM] Add';

    constructor(public payload: string, public body: any = null) { }
}

export class AddItemOption {
  static readonly type = '[ITEM] Add Item Option';

  constructor(public item: Item, public item_option_type: string) { }
}

export class UpdateItem {
    static readonly type = '[ITEM] Update';

    constructor(public payload: Item | SwissBackup | any) { }
}

export class RemoveItem {
    static readonly type = '[ITEM] Remove';

    constructor(public payload: Item, public from_web_component: boolean = false) { }
}

export class RemoveItemOption {
  static readonly type = '[ITEM] Remove Item Option';

  constructor(public item: Item, public item_option_type: string) { }
}

export class RemoveItemOptionSuccess {
  static readonly type = '[ITEM] Remove Item Option Sucess';

  constructor(public item: Item) { }
}

export class BulkAddItemOption {
  static readonly type = '[ITEM] Bulk Add Item Option';

  constructor(public payload: { items: { [param: number]: string[] } }) { }
}

export class BulkRemoveItem {
    static readonly type = '[ITEM] Bulk Remove';

    constructor(public payload: Item[]) { }
}

export class BulkRemoveItemOption {
  static readonly type = '[ITEM] Bulk Remove Item Option';

  constructor(public payload: any) { }
}

export class RemoveItemSuccess {
    static readonly type = '[ITEM] RemoveSuccess';

    constructor(public cartItem: Item) { }
}

export class RemoveItemCancelled {
    static readonly type = '[ITEM] RemoveCancelled';

    constructor(public cartItem: Item) { }
}

export class AddSubItem {
    static readonly type = '[SUBITEM] Add';

    constructor(public payload: { parent: Item, subitemtype: string }, public body: any = null) { }
}

export class UpdateSubItem {
    static readonly type = '[SUBITEM] Update';

    constructor(public payload: { parent: Item, subitem: Item }) { }
}

export class RemoveSubItem {
    static readonly type = '[SUBITEM] Remove';

    constructor(public payload: { parent: Item, subitem: Item }, public from_web_component: boolean = false) { }
}

export class BulkRemoveSubItem {
    static readonly type = '[SUBITEM] Bulk Remove';

    constructor(public payload: { parent: Item, subitem: Item }[]) { }
}

export class InitItem {
    static readonly type = '[ITEM] Init';

    constructor(public payload: Item[]) { }
}
export class ResetItem {
    static readonly type = '[ITEM] Reset';

    constructor(public payload: Item) { }
}

export class GetConfiguration {
    static readonly type = '[ITEM] GetConfiguration';

    constructor(public type: string) { }
}

// ____________ FIN_ITEM ______________

// ____________ NAV ______________

export class SetNavigation {
    static readonly type = '[NAVIGATION] Set';

    constructor(public payload: { current_step: string; target: number; goTo?: string }) { }
}

export class EndNavigation {
    static readonly type = '[NAVIGATION] Stop loaders';

    constructor() { }
}

// ____________ FIN_NAV ______________

export class SetLangDispatched {
    static readonly type = '[LANGUAGE] Dispatch';
}

export class SetLangSuccessful {
    static readonly type = '[LANGUAGE] Success';
}


export class GetCGU {
    static readonly type = '[CGU] GET';

    constructor() { }
}

export class AddTempContact {
    static readonly type = '[CONTACT] Add';

    constructor(public payload: any) { }
}

export class UpdateTempContact {
    static readonly type = '[CONTACT] Update';

    constructor(public payload: any) { }
}

export class DomainBulkAdd {
    static readonly type = '[DOMAIN] Bulk Add';

    constructor(public payload: any) { }
}

export class DomainBulkUpdate {
    static readonly type = '[DOMAIN] Bulk Update';

    constructor(public payload: any) { }
}

export class DomainBulkAddSubItems {
    static readonly type = '[DOMAIN] Bulk Add SubItem';

    constructor(public payload: any) { }
}

export class DomainBulkRemoveSubItems {
    static readonly type = '[DOMAIN] Bulk Remove SubItem';

    constructor(public payload: any) { }
}

export class VatSwitchToggle {
    static readonly type = '[VAT] Toggle';

    constructor(public inclVat: string, public markForCheck: boolean = false) { }
}

export class VatUpdate {
    static readonly type = '[VAT] Update';

    constructor(public cart: any) { }
}
