import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import {AlertModule, LoaderModule, NotificationModule} from '@infomaniak/angular-common';
import { HeaderTitleModule } from '@infomaniak/ngx-header-title';
import { TranslocoModule } from '@ngneat/transloco';
import { SwitchDialogComponent } from '@app/store/dialogs/switch.component';
import { DeleteDialogComponent } from '@app/store/dialogs/delete.component';
import { IbanDialogComponent } from '@app/store/dialogs/iban.component';
import { CannotCheckoutComponent } from '@app/store/dialogs/b2b/cannot-checkout.component';
import { DeferredPaymentComponent } from '@app/store/dialogs/deferred-payment/deferred-payment.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    DeleteDialogComponent,
    IbanDialogComponent,
    SwitchDialogComponent,
    CannotCheckoutComponent,
    DeferredPaymentComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    TranslocoModule,
    LoaderModule,
    HeaderTitleModule,
    MatDialogModule,
    MatButtonModule,
    NotificationModule,
    HeaderTitleModule,
    AlertModule,
  ],
  exports: [
    DeleteDialogComponent,
    IbanDialogComponent,
    SwitchDialogComponent,
    CannotCheckoutComponent,
    DeferredPaymentComponent,
  ],
})
export class StoreModule {}
