import {Component, NgZone, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {NavigationService} from "@app/core/navigation/navigation.service";

@Component({
  selector: 'app-deferred-payment',
  templateUrl: './deferred-payment.component.html',
  styleUrls: ['./deferred-payment.component.scss']
})
export class DeferredPaymentComponent implements OnInit {

  loading = false;
  constructor(
    private dialogRef: MatDialogRef<DeferredPaymentComponent>,
    private navigationService: NavigationService,
    private ngZone: NgZone) { }

  ngOnInit(): void {
  }

  save() {
    // PAYE EN IBAN
    this.loading = true;
    this.navigationService.isNavigating().next(true);
    this.dialogRef.close('true');

  }


  close() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

}
