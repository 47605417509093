<app-header *ngIf="(links$ | async)"></app-header>
<div [class.full-height-with-header]="!(links$ | async)"
     class="full-height-without-header"
     [class.white-background]="hasWhiteBackground">
  <router-outlet></router-outlet>
</div>

<div
  class="floating-buttons"
  [class.floating-buttons--without-bottom-bar]="hasBottomBarHidden$ | async"
>
  <button *ngIf="showGoTop"
          [@enterAnimation]
          (click)="scrollToTop()"
          class="back-to-top">
    <span class="icon icon-chevron-up icon-size-15 white"></span>
  </button>

  <div
    class="wc-trigger-helpdesk"
    [style.display]="showSupportModule ? 'block' : 'none'"
  >
    <app-helpdesk-trigger></app-helpdesk-trigger>
  </div>

  <div class="wc-trigger-reporting-tools">
    <module-reporting-tools-component></module-reporting-tools-component>
  </div>
</div>

<!-- We use the display css proeprty instead of an if, because we need to be able to select the wrapper immediately after trying to display it -->
<div class="captcha-wrapper" [style.display]="(displayVisualChallengeOverlay$ | async) ? 'flex' : 'none'">
    <div id="grecaptcha-wrapper"></div>
</div>
