import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoaderService } from '@app/core/loader.service';
import { AppConstants } from '@app/shared/constantes';
import { VatUpdate } from '@app/store/actions/cart.action';
import {
  CardToken,
  Checkout3DS,
  PaymentRedirect,
  ClearPaymentCache,
  CreateAddress,
  DeferredInvoice,
  DenyIban,
  GetFraudScore,
  PaymentCache,
  PaymentCheckout,
  PaymentTarget,
  SetAddress,
  SetDefaultProfilePayment,
  SetPrepraidComplement,
  SetRenewal,
  UpdateAddress,
  UpdatePaymentCache,
} from '@app/store/actions/payment.action';
import { NotificationService } from '@infomaniak/angular-common';
import { translate, TranslocoService } from '@ngneat/transloco';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { NavigationService } from '../../core/navigation/navigation.service';
import { CannotCheckoutComponent, } from '../dialogs/b2b/cannot-checkout.component';
import { IbanDialogComponent } from '../dialogs/iban.component';
import { PaymentStateModel } from '../model/state.model';
import { isInteger } from 'lodash';
import { DeferredPaymentComponent } from "@app/store/dialogs/deferred-payment/deferred-payment.component";

@State<PaymentStateModel>({
  name: 'paymentState',
  defaults: {
    payment: null,
    id: null,
    payment_method: null,
    card_uri: null,
    postfinance_data: null,
    target: null,
    fraudscore: null,
    deferred_invoice: false,
    set_default: false,
    selected_payment_is_default: false
  }
})
@Injectable()
export class PaymentState {
  params = '';
  complete_with_prepaid = false;
  renewal = false;
  addressId: number;

  constructor(private http: HttpClient, private navigationService: NavigationService, private loader: LoaderService,
              private notificationService: NotificationService, private dialog: MatDialog, private translate: TranslocoService) {
  }

  @Selector()
  static payment(state: PaymentStateModel) {
    return state.payment;
  }

  @Selector()
  static target(state: PaymentStateModel) {
    return state.target;
  }

  @Selector()
  static cardUri(state: PaymentStateModel) {
    return state.card_uri;
  }

  @Selector()
  static fraudscore(state: PaymentStateModel) {
    return state.fraudscore;
  }

  @Selector()
  static postfinance(state: PaymentStateModel) {
    return state.postfinance_data;
  }

  @Action(PaymentCache)
  paymentCache({ getState, setState }: StateContext<PaymentStateModel>) {
    return this.http.get('/api/payment/init').pipe(
      map((result: any) => result.data),
      tap(result => {
        setState({ ...getState(), payment: result });
      })
    );
  }

  @Action(GetFraudScore)
  fraudScore({ getState, setState }: StateContext<PaymentStateModel>) {
    return this.http.get('/api/fs/infos').pipe(
      map((result: any) => result.data),
      tap(result => {
        if (result && result.redirect) {
          window.location.assign(result.redirect);
        }
        setState({ ...getState(), fraudscore: result });
      })
    );
  }

  @Action(UpdatePaymentCache)
  updatePayment({ getState, setState, dispatch }: StateContext<PaymentStateModel>) {
    let params = (this.addressId ? 'address_id=' + this.addressId : '');
    params += (this.complete_with_prepaid ? (params !== '' ? '&' : '') + 'complete_with_prepaid=true' : '');

    this.loader.item.set(true);

    return this.http.get('/api/payment/update' + (params.length ? '?' + params : '')).pipe(
      map((result: any) => result.data),
      tap(result => {
        setState({ ...getState(), payment: result });

        dispatch(new VatUpdate(result.cart));

        document.dispatchEvent(new CustomEvent('updateCart', {
          detail: result.cart || []
        }));

        this.loader.item.set(false);
      })
    );
  }

  @Action(CreateAddress)
  createAddress(
    { getState, patchState }: StateContext<PaymentStateModel>,
    { payload }: CreateAddress
  ) {
    if (window['PAYMENT_ACCOUNT'] && window['PAYMENT_ACCOUNT'].id) {
      const account_id = window['PAYMENT_ACCOUNT'].id;
      return this.http
                 .post(`/api/invoicing/${account_id}/addresses`, payload)
                 .pipe(
                   tap((response: any) => {

                     if (response.result === 'success') {
                       this.notificationService.add(translate('Addresse enregistree'), 'success',
                         { duration: 5000 }, null);
                       patchState({
                         payment: {
                           ...getState().payment,
                           addresses: [
                             response.data,
                             ...getState().payment.addresses,
                           ]
                         }
                       });
                     } else {
                       this.notificationService.add(translate(AppConstants.NOTIFICATION_CREATE_ERROR), 'error',
                         { duration: 5000 }, null);
                     }
                   })
                 );
    }
  }

  @Action(UpdateAddress)
  updateAddress(
    { getState, patchState }: StateContext<PaymentStateModel>,
    { payload, id }: UpdateAddress
  ) {
    if (window['PAYMENT_ACCOUNT'] && window['PAYMENT_ACCOUNT'].id) {
      const account_id = window['PAYMENT_ACCOUNT'].id;
      return this.http
                 .put(`/api/invoicing/${account_id}/addresses/${id}`, payload)
                 .pipe(
                   tap((response: any) => {

                     if (response.result === 'success') {
                       this.notificationService.add(translate('Adresse mise a jour'), 'success',
                         { duration: 5000 }, null);
                       patchState({
                         payment: {
                           ...getState().payment,
                           addresses: [
                             response.data,
                             ...getState().payment.addresses,
                           ]
                         }
                       });
                     } else {
                       this.notificationService.add(translate(AppConstants.NOTIFICATION_UPDATE_ERROR), 'error',
                         { duration: 5000 }, null);
                     }
                   })
                 );
    }
  }

  @Action(SetAddress)
  setAddress(
    { getState, patchState }: StateContext<PaymentStateModel>,
    { payload }: SetAddress
  ) {
    if (payload) {
      this.addressId = payload;
    }
  }

  @Action(PaymentCheckout)
  paymentCheckout(
    { getState, patchState, dispatch }: StateContext<PaymentStateModel>,
    {}: PaymentCheckout
  ) {
    if (getState().payment?.use_customer_payment) {
      // expired card?
      const expiredCard = getState().target === 'card' && getState().payment?.payment_profiles?.[0]?.expired;

      // enough money on prepraid?
      const prepaidNotEnoughMoney = getState().target === 'prepaid' && !getState()?.payment?.prepay_balance_available;

      if (expiredCard || prepaidNotEnoughMoney) {
        // open modal
        const dialogConfig = new MatDialogConfig();

        const clientName = window['CURRENT_ACCOUNT']?.organization_name;
        const cardDetails = expiredCard ?
          getState().payment?.payment_profiles?.[0].details.label || '****' + getState().payment?.payment_profiles?.[0].details.last4
          + ' - ' + getState().payment?.payment_profiles?.[0].details.name
          : '';
        const ppErrorDetails = expiredCard ?
          translate('La carte de credit $cardDetails$ est expiree', { cardDetails }) : translate('Le solde du compte prepaye du client est insuffisant');

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'dialog--medium';
        dialogConfig.data = { clientName, ppErrorDetails };

        const dialogRef = this.dialog.open(CannotCheckoutComponent, dialogConfig);

        let body = {};
        let params = this.complete_with_prepaid ? 'complete_with_prepaid=true' : '';
        if (getState().target === 'card') {
          if (getState().id) {
            body = { card_tokenised: getState().id };
          } else if (this.params) {
            params += (this.complete_with_prepaid ? '&' : '') + 'id=' + this.params;
          }
          if (getState().payment_method === 'CB') {
            body['payment_method'] = 'CB';
          }
          if (getState().set_default && getState().selected_payment_is_default == false) {
            body['set_default'] = getState().set_default;
          }
        } else if (getState().target === 'twint') {
          if (this.params) {
            params += (this.complete_with_prepaid ? '&' : '') + 'id=' + this.params;
          }
        }
        params += (params !== '' ? '&' : '') + 'address_id=' + this.addressId;
        params += (params !== '' ? '&' : '') + 'enable_auto_renew=' + this.renewal;

        dialogRef.afterClosed().subscribe(result => {
          if (result === true || result === 'true') {
            this.loader.set(true);
            // send email and go to summary

            return this.http
                       .post(`/api/payment/${getState().target}?${params}`, body)
                       .pipe(
                         tap((response: any) => {
                           if (response.result === 'success') {
                             if (!response.data.payment_ok) {
                               window.location.assign('/summary');
                             } else {
                               window.location.assign('/');
                             }
                           } else {
                             console.error('its a nop' + response);
                             this.loader.set(false);
                           }
                         })
                       ).toPromise();
          }
        });
        return;
      }
    }
    this.loader.set(true);
    window.sessionStorage.removeItem('domain_research');

    if (getState().target && getState().target !== '') {
      let body = {};
      let params = this.complete_with_prepaid ? 'complete_with_prepaid=true' : '';
      if (getState().target === 'card') {
        if (getState().id) {
          body = { card_tokenised: getState().id };
        } else if (this.params) {
          params += (this.complete_with_prepaid ? '&' : '') + 'id=' + this.params;
        }
        if (getState().payment_method === 'CB') {
          body['payment_method'] = 'CB';
        }
        if (getState().set_default && getState().selected_payment_is_default == false) {
          body['set_default'] = getState().set_default;
        }
      } else if (getState().target === 'twint') {
        if (this.params) {
          params += (this.complete_with_prepaid ? '&' : '') + 'id=' + this.params;
        }
      }
      params += (params !== '' ? '&' : '') + 'address_id=' + this.addressId;
      params += (params !== '' ? '&' : '') + 'enable_auto_renew=' + this.renewal;
      params += (params !== '' ? '&' : '') + 'deferred_invoice=' + getState().deferred_invoice;

      if (getState().target === 'iban' || getState().target === 'deferred_payment') {
        this.loader.set(false);
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'dialog--medium';
        dialogConfig.data = {
          country: '@TODO'
        };

        const dialogRef = getState().target === 'iban' ?
          this.dialog.open(IbanDialogComponent, dialogConfig)
          : this.dialog.open(DeferredPaymentComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result === 'true') {
            this.loader.set(true);
            return this.http
                       .post(`/api/payment/${getState().target}?${params}`, body)
                       .pipe(
                         tap((response: any) => {
                           if (response.result === 'success') {
                             if (!response.data.payment_ok) {
                               if (getState().target === 'deferred_payment' || response.data.iban_data || response.data.b2b) {
                                 window.location.assign('/summary');
                               }
                             } else {
                               window.location.assign('/');
                             }
                           } else {
                             console.error('its a nop' + response);
                             this.loader.set(false);
                           }
                         })
                       ).toPromise();
          } else {
            this.loader.set(false);
            dispatch(DenyIban);
          }
        });
      } else {
        return this.http
                   .post(`/api/payment/${getState().target}?${params}`, body)
                   .pipe(
                     tap((response: any) => {
                       if (response.result === 'success') {
                         if (!response.data.payment_ok && !response.data.b2b) {
                           // en cas de payment paypal; redirect url ici
                           if (response.data.paypal_data?.redirect_to) {
                             dispatch(new PaymentRedirect(response.data.paypal_data.redirect_to));
                           }
                           if (response.data.twint_data?.redirect_to) {
                             dispatch(new PaymentRedirect(response.data.twint_data.redirect_to));
                           }
                           // en cas de nouvelle carte, check 3ds ici; frame url
                           if (response.data.card_data) {
                             if (response.data.card_data.fullframe && response.data.card_data.frame_url) {
                               dispatch(new PaymentRedirect(response.data.card_data.frame_url));
                             } else {
                               patchState({
                                 card_uri:
                                 response.data.card_data.frame_url
                               });
                               dispatch(new Checkout3DS(true));
                               this.navigationService.isNavigating().next(false);
                               this.loader.set(false);
                             }
                           }
                           if (response.data.postfinance_data) {
                             patchState({
                               postfinance_data:
                               response.data.postfinance_data
                             });
                             this.navigationService.isNavigating().next(false);
                             this.loader.set(false);
                           }
                         } else {
                           window.location.assign('/');
                         }
                       } else {
                         console.error('its a nop' + response);
                         this.loader.set(false);
                       }
                     }),
                     catchError(err => {
                       this.loader.item.set(false);
                       if (getState().target === 'card') {
                         if (err.error.error.description === 'should_reload') {
                           window.location.reload();
                         } else {
                           this.notificationService.add(translate(err.error.error.description), 'error', {
                             duration: 5000,
                           }, null);
                         }
                       }
                       this.loader.set(false);
                       return throwError(err);
                     })
                   );
      }
    } else {
      console.warn('No payment target. Aborted checkout.');
      this.notificationService.error(translate('Vous devez selectionner ou ajouter un moyen de paiement'));
      this.loader.set(false);

    }

  }

  @Action(PaymentTarget)
  paymentTarget(
    { getState, patchState }: StateContext<PaymentStateModel>,
    { target, id, payment_method, selected_payment_is_default }: PaymentTarget
  ) {
    patchState({
      target: target || '',
      payment_method: payment_method || null,
      id: !isInteger(id) ? (id || '') : null,
      selected_payment_is_default: selected_payment_is_default
    });
    this.params = id || '';
  }

  @Action(DeferredInvoice)
  deferredInvoice(
    { patchState }: StateContext<PaymentStateModel>,
    { value }: DeferredInvoice
  ) {
    patchState({
      deferred_invoice: value,
    });
  }

  @Action(CardToken) cardToken({getState, patchState, dispatch}: StateContext<PaymentStateModel>,
            { payload }: CardToken) {
    return this.http
               .post(`/api/payment/save_card`, payload)
               .pipe(
                 tap((response: any) => {
                   if (response.result === 'success') {
                     if (response.data.check_3ds) {
                       if (response.data.redirect_url) {
                         dispatch(new PaymentRedirect(response.data.redirect_url));
                       } else {
                         this.notificationService.add(translate(AppConstants.NOTIFICATION_CREATE_ERROR),
                           'error', { duration: 5000 }, null);
                       }
                     }
                     const state = { target: 'card' };
                     if (payload.payment_method === 'CB') {
                       state['payment_method'] = 'CB';
                     }
                     patchState(state);
                     this.params = response.data;
                     this.notificationService.add(translate('Carte enregistree'),
                       'success', { duration: 5000 }, null);
                   } else {
                     this.notificationService.add(translate(AppConstants.NOTIFICATION_CREATE_ERROR),
                       'error', { duration: 5000 }, null);
                   }
                 })
               );

  }

  @Action(Checkout3DS)
  checkout3ds({ getState, patchState }: StateContext<PaymentStateModel>,
              { payload }: Checkout3DS) {
    if (payload === false) {
      patchState({
        card_uri: null
      });
    }
  }

  @Action(SetPrepraidComplement)
  setPrepaidComplement(
    { getState, patchState }: StateContext<PaymentStateModel>,
    { payload }: SetPrepraidComplement
  ) {
    this.complete_with_prepaid = payload;
  }

  @Action(SetRenewal)
  setRenewal(
    { getState, patchState }: StateContext<PaymentStateModel>,
    { payload }: SetRenewal
  ) {
    this.renewal = payload;
  }

  @Action(SetDefaultProfilePayment)
  setDefaultProfilePayment(
      { patchState }: StateContext<PaymentStateModel>,
      { payload }: SetDefaultProfilePayment
  ) {
    patchState({
      set_default: payload
    });
  }


  @Action(ClearPaymentCache)
  clearPaymentCache({ getState, setState }: StateContext<PaymentStateModel>) {
    setState({ ...getState(), payment: null });
  }

}
