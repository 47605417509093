import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeES from '@angular/common/locales/es';
import localeFR from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/it';
import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { InitCart, SetLangDispatched, SetLangSuccessful, } from '@app/store/actions/cart.action';
import { Store } from '@ngxs/store';

import { environment } from '../../environments/environment';
import { Logger } from './logger.service';
import { TranslocoService } from '@ngneat/transloco';

export function normalizeCommonJSImport<T>(
  importPromise: Promise<T>,
): Promise<T> {
  // CommonJS's `module.exports` is wrapped as `default` in ESModule.
  return importPromise.then((m: any) => (m.default || m) as T);
}

const log = new Logger('I18nService');
const languageKey = 'language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s: string) {
  return s;
}

@Injectable()
export class I18nService {
  initedLang = false;
  defaultLanguage: string;
  supportedLanguages: string[];
  isTranslationsLoaded = false;

  constructor(private translateService: TranslocoService, private meta: Meta, private store: Store, private http: HttpClient) {
    // Embed languages to avoid extra HTTP requests
    // const languages: any[] = window['LANGUAGES'] || null;
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.getActiveLang();
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  setLanguage(language: string, reloadData = false) {
    language = language || window['CONST_LANG'].shortcode || 'en';
    try {
      window['CONST_LANG'] = {
        longcode: this.getLongFromShort(language).substring(0, 2) + '_' + this.getLongFromShort(language).substring(2, 4),
        shortcode: language
      };
    } catch (e) {
      console.error(e);
    }
    setTimeout(() => {
      this.store.dispatch(new SetLangDispatched);
      let promise = Promise.resolve({});
      if (this.initedLang) {
        promise = this.http
                      .put(
                        '/api/lang',
                        JSON.stringify({ lang: language })
                      ).toPromise();

      } else {
        this.initedLang = true;
      }
      return promise.then(() => {
        // OK
        return this.languageMapping(language);
      }).then((x) => {
        this.translateService.setTranslation(x, language);
        registerLocaleData(this.localeMapping(language));
        this.translateService.setActiveLang(language);
        this.meta.updateTag({ name: 'language', content: language });
        if (reloadData) {
          if (window['CURRENT_STATE'].includes('order')) {
            return this.store.dispatch(new InitCart(true, true)).toPromise();
          } else {
            return this.store.dispatch(new InitCart(null));
          }
        }
      }).then(() => {
        this.isTranslationsLoaded = true;
        window['CONST_LANG'] = {
          longcode: this.getLongFromShort(language, true),
          shortcode: language
        };
        return this.store.dispatch(new SetLangSuccessful);
      }).catch(e => {
        console.error('Changing language failed');
        return this.store.dispatch(new SetLangSuccessful);
      });
    });
  }

  languageMapping(lang) {
    switch (lang) {
      case 'fr':
        return normalizeCommonJSImport(
          import(/* webpackChunkName: "frFR" */ '../../translations/fr_FR.json'),
        );
      case 'de':
        return normalizeCommonJSImport(
          import(/* webpackChunkName: "deDE" */ '../../translations/de_DE.json'),
        ).then((m: any) => (m.default || m));
      case 'en':
        return normalizeCommonJSImport(
          import(/* webpackChunkName: "enGB" */ '../../translations/en_GB.json'),
        );
      case 'es':
        return normalizeCommonJSImport(
          import(/* webpackChunkName: "esES" */ '../../translations/es_ES.json'),
        ).then((m: any) => (m.default || m));
      case 'it':
        return normalizeCommonJSImport(
          import(/* webpackChunkName: "itIT" */ '../../translations/it_IT.json'),
        ).then((m: any) => (m.default || m));
      default:
        return normalizeCommonJSImport(
          import(/* webpackChunkName: "enGB" */ '../../translations/en_GB.json'),
        );
    }
  }

  localeMapping(lang) {
    switch (lang) {
      case 'fr':
        return localeFR;
      case 'de':
        return localeDE;
      case 'en':
        return localeEN;
      case 'it':
        return localeIT;
      case 'es':
        return localeES;
      default:
        // return this.en();
        break;
    }
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    if (localStorage) {
      localStorage.setItem(languageKey, environment.defaultLanguage);
    }
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.translateService.langChanges$.subscribe(
      (event) => {
        if (localStorage) {
          localStorage.setItem(languageKey, event);
        }
      }
    );
    return this.setLanguage('');
  }

  getLongFromShort(langShort: string, underscore = false) {
    const lang = {
      fr: 'fr_FR',
      en: 'en_GB',
      de: 'de_DE',
      it: 'it_IT',
      es: 'es_ES'
    };
    if (underscore) {
      return lang[langShort];
    } else {
      return lang[langShort].split('_').join('');
    }
  }
}
