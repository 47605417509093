<!-- HEADER -->
<button class="close icon icon-cross icon-size-16"
        mat-button
        (click)="close()"
        mat-dialog-close
        [attr.aria-label]="'Fermer' | transloco"></button>
<!-- BODY -->
<mat-dialog-content>
    <div class="notice-box padding-bottom-30">
        <span class="icon icon-exclamation icon-size-40"></span>
    </div>
    <div class="notice-box">
        <div class="notice-box__message big">
            {{'Attention' | transloco}}</div>
        <div class="notice-box__message">
            {{'Votre client devra finaliser cette commande' | transloco}}</div>
    </div>
    <div class="content">
        <!-- Current record value -->
        <div class="content__record">
            <div class="content__record__label2"
                 [innerHTML]="'Le moyen de paiement configure par defaut sur le compte Infomaniak de votre client
            $clientName$ rencontre un probleme :' | transloco:{ clientName: '<strong>' + clientName + '</strong>' }">
            </div>
            <div class="content__record--error">
                {{ppErrorDetails}}
            </div>
        </div>
        <!-- Required record value -->
        <div class="content__record">
            <div class="content__record__label">
                {{ 'Vous pouvez finaliser la commande mais un email sera automatiquement envoye a votre client $clientName$ afin de lui demander de la finaliser en procedant au reglement avec un moyen de paiement valide.' | transloco:{ clientName: clientName } }}
            </div>
        </div>
    </div>
</mat-dialog-content>
<!-- FOOTER -->
<div class="mat-dialog-footer">
    <div class="block-buttons clearfix">
        <button mat-flat-button
                mat-dialog-close
                (click)="close()"
                [attr.aria-label]="'Fermer' | transloco"
                color="accent">
            {{ 'Fermer' | transloco }}
        </button>
        <button (click)="checkout()"
                mat-dialog-close
                class="ml-auto"
                mat-flat-button
                color="primary">
            {{ 'Finaliser la commande' | transloco }}
        </button>
    </div>
</div>
