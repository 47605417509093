export const WHITE_BACKGROUND_URLS = [
  '/ksuite_change_offer',
  '/vps_change_offer',
  '/support_premium_change_offer',
  'order2/recommendation',
  'order2/select',
  'order2/payment',
  'order/payment',
  '/bulk_transfer_domain',
  '/bulk_registration_domain',
  'hosting_change_offer',
];

export function urlIncludes(paths: string[]): boolean {
  return Boolean(paths.find((path) => window.location.pathname.includes(path)));
}
