import { Component, NgZone, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { NavigationService } from '@app/core/navigation/navigation.service';

@Component({
  selector: 'iban-dialog',
  templateUrl: './iban.component.html',
  styleUrls: ['./iban.component.scss']
})

export class IbanDialogComponent implements OnInit {
  loading = false;

  constructor(
    private dialogRef: MatDialogRef<IbanDialogComponent>,
    private navigationService: NavigationService,
    private ngZone: NgZone) {
  }

  ngOnInit() {
  }

  save() {
    // PAYE EN IBAN
    this.loading = true;
    this.navigationService.isNavigating().next(true);
    this.dialogRef.close('true');

  }


  close() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

}
