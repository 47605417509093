import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule
} from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { I18nService } from './i18n.service';
import { ShopInterceptor, HTTPStatus } from './http/shop.interceptor';

@NgModule({
    imports: [CommonModule, HttpClientModule, TranslocoModule, RouterModule],
    providers: [
        AuthenticationService,
        AuthenticationGuard,
        I18nService,
        HttpClient,
        { provide: HTTP_INTERCEPTORS, useClass: ShopInterceptor, multi: true },
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy
        },
        HTTPStatus
    ]
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        // Import guard
        if (parentModule) {
            throw new Error(
                `${parentModule} has already been loaded. Import Core module in the AppModule only.`
            );
        }
    }
}
