/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from '@app/app.module';
import {environment} from '@env/environment';
import {BrowserTracing} from '@sentry/tracing';
import * as Sentry from '@sentry/angular-ivy';

const storage = sessionStorage || localStorage || null;

export function ReadableVar(variable: unknown): string {
  if (typeof variable === 'string') {
    return variable;
  }
  if (typeof variable === 'object') {
    return JSON.stringify(variable);
  }
  if (variable.toString) {
    return variable.toString();
  }
  return String(variable);
}

export function logProfile(): Record<string, unknown> | null {
  if (storage) {
    const profile = JSON.parse(storage.getItem('profile'));

    if (!profile || !profile?.cart?.items?.[0]) {
      return null;
    }

    const {attributes, setup, validation} = profile.cart.items[0];
    const {navigation, cartSetup = setup} = profile.cart;

    return {
      cart: {
        setup: ReadableVar(cartSetup),
        navigation: ReadableVar(navigation)
      },
      item: {
        attributes: ReadableVar(attributes),
        setup: ReadableVar(setup),
        validation: ReadableVar(validation)
      }
    };
  }
}

window['app_version'] = environment.version;


const sentryInitProperties: Sentry.BrowserOptions = {
  dsn: 'https://03a4f7431fd0453a83f1a57b8f4d54de@sentry.infomaniak.com/5',
  tracesSampleRate: 0,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['*.infomaniak.ch', '*.infomaniak.com'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],


  environment: window['APP_ENV'],

  initialScope: scope => {
    if (window['CONST_USER']) {
      scope.setUser({
        USER_ID: window['CONST_USER']?.id ?? 'not_connected',
        ACCOUNT_ID: window['CURRENT_ACCOUNT']?.id ?? 'not_connected',
        IS_AUTOLOG: window['IS_AUTOLOG'] ?? false,
        IS_STAFF: window['IS_STAFF'] ?? false
      });
    }

    scope.setTags({
      APP_VERSION: window['app_version'],
      COUNTRY: window['COUNTRY'],
      CONST_CURRENCY: window['CONST_CURRENCY']?.label,
      CONST_LANG: window['CONST_LANG']?.shortcode,
      CURRENT_STATE: window['CURRENT_STATE'],
      CURRENT_ORDER_PARAMETERS: ReadableVar(window['CURRENT_ORDER_PARAMETERS']),
      ERROR_CODE: window['ERROR_CODE'] ? ReadableVar(window['ERROR_CODE']) : '',
      REQUEST_CONTEXT_ID: window['REQUEST_CONTEXT_ID'],
    });

    const profilMe = logProfile();
    if (profilMe) {
      scope.setContext('profilme', profilMe);
    }

    if (window['INVITATION']) {
      scope.setContext('invitation', window['INVITATION']);
    }

    return scope;
  },
};

if (!environment.version.includes('-rc.')) {
  sentryInitProperties.release = environment.version;
}


Object.values = Object.values || ((obj) => {
  return Object.keys(obj).map(x => obj[x]);
});

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function (...args) {
      window?.['reporting']?.log?.(...args);
    };
    window.console.warn = function (...args) {
      window?.['reporting']?.warn?.(...args);
    };
  }
} else {
  sentryInitProperties.defaultIntegrations = false;
}

Sentry.init(sentryInitProperties);

if (localStorage && localStorage.getItem('incl_vat') === null) {
  localStorage.setItem('incl_vat', window['TAX_MODE']?.incl_vat === true ? 'true' : 'false');
}

console.warn('Bootstrapping');

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
