import { PlatformLocation } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Navigation } from '@app/models/navigation';
import { InitCart, SetNavigation } from '@app/store/actions/cart.action';
import { CartState } from '@app/store/state/cart.state';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';

export const specials = [
  '/signup',
  '/signup/account',
  '/signup/promotional',
  '/signup/affiliation',
  '/signup/safetracing',
  '/signup/etickets',
  '/auth/login',
  '/auth/logout',
  '/auth/autologout',
  '/update/order/domain',
  '/invitation/account',
  '/invitation/workspace',
  '/invitation/entertainment',
  '/invitation/drive',
  '/invitation/transfer',
  '/invitation/success',
  '/renewal',
  '/change_account',
  '/summary',
  '/error',
  '/fs',
  '/secure-profile',
  '/secure-profile/confirm-connection',
];

const newDomainsRecommendation = ['domain', 'domain_ksite', 'dns', 'dns_free', 'hosting', 'email_hosting', 'vps'];

@Injectable()
export class NavigationService implements OnDestroy {
  singloton: NavigationService;
  @Select(CartState.navigation)
  nav$: Observable<Navigation>;
  @Select(CartState.referer)
  ref$: Observable<string>;
  winState = window['CURRENT_STATE'];
  currentRoad = '';
  cachedRoute: string[] = [];
  history: { route: string; last: boolean }[] = [];
  nav: Navigation = null;
  referer = '';
  tracked = window['trckd'] || false;
  private isNavigating$ = new BehaviorSubject<boolean>(true);
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private location: PlatformLocation,
    private actions: Actions
  ) {
    this.singloton = this;
    if (!this.tracked) {
      this.tracked = true;
      window['trckd'] = true;
      this.firstTrack();
    }

    // this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
    //     if (x instanceof NavigationEnd) {
    //         this.isNavigating().next(false);
    //     }
    // });
    this.ref$.pipe(takeUntil(this.onDestroy$)).subscribe((ref) => {
      this.referer = ref;
    });

    this.nav$.subscribe((state) => {
      const oldNav = this.nav;
      this.nav = state;
      if (this.nav && this.nav.workflow && this.nav.workflow.find((x) => x.faq)) {
        const faq = this.nav.workflow.find((x) => x.faq).faq;
        window['SERVICE_ID'] = faq.service_id;
        window['CATEGORY_ID'] = faq.category_id;
        window['PROJECT_SLUG'] = faq.project;
      }
      if (window['ERROR_CODE']) {
        console.log('error_code', window['ERROR_CODE']);
        this.router.navigateByUrl('/error', {
          state: { custom_error: window['ERROR_CODE'] },
        });
      }

      // If we are on a route that exists out of the order one, we must let the user, treat any specificity in the method specialCase()
      console.warn(window.location.pathname);
      if (specials.indexOf(this.winState) > -1 || specials.indexOf(window.location.pathname) > -1) {
        this.specialCase(specials.indexOf(this.winState) > -1 ? this.winState : window.location.pathname);
        return;
      }
      if (state) {
        // If there is no workflow || current_step, user should not be on the shop! DEV => Redirect to INDEX
        if (state && (!state.workflow || !state.current_step)) {
          this.router.navigate(['index']);
          return;
        }
        if (oldNav && state.current_step === oldNav.current_step) {
          return;
        }
        this.isNavigating().next(true);
        const r1 = this.winState.indexOf('signup_') >= 0 ? 'order' : this.winState;
        const currentStep = state.workflow.find((x) => x.order === state.current_step);
        const r2 = currentStep.name;
        const r3 = currentStep.type ? currentStep.type : '';
        console.warn(`going to ${r1}, ${r2}, ${r3}`);

        Sentry.addBreadcrumb({
          category: 'navigation',
          message: `going to ${r1}, ${r2}, ${r3}`,
          level: 'info',
        });

        this.lazyNavigate(r1, r2, r3);
      }
    });
    this.location.onPopState((e) => {
      // in case of the user use history back, redirect the user on the base / (handled by navigationService after that)
      this.isNavigating().next(true);
      const currentIndex = this.nav && this.nav.current_step ? this.nav.current_step : null;
      if (currentIndex) {
        if (currentIndex <= 1) {
          window.location.assign(this.referer);
        } else {
          this.store.dispatch(new SetNavigation({ current_step: null, target: -1 }));
        }
      } else {
        this.isNavigating().next(false);
      }
    });
  }

  // Due to every component being lazy loaded, childrens included, we need to navigate throught B to reach C from A, because A
  // can't load C directly. This method parse an array of string [A,B,C,D,..] to load routes A/B/C/D it goes through A, A/B, A/B/C, A/B/C/D....
  // There is a cache array, usefull because it enable to go directly from A/B to A/B/C/D if A/B/C has already been visited.
  lazyNavigate(...$n: string[]) {
    let navigationNumber = 0;
    let has_navigated = false;
    try {
      $n = $n.filter((x) => x);
      // if different of the current route, lets go
      if ($n.includes('recommendation') && newDomainsRecommendation.includes($n[$n.length - 1])) {
        // if (switchToNewOrderDesign(this.route) && $n.includes('recommendation')) {
        const oldTarget = $n.pop();
        $n.push(`new_${oldTarget}`);
      }
      if ($n.join('/') !== this.currentRoad) {
        this.currentRoad = $n.join('/');
        let i = 1;
        while (i <= $n.length) {
          const array = Object.assign([], $n);
          array.splice(i);
          if (i === $n.length || (array.join('/') && this.cachedRoute.indexOf(array.join('/').toString()) < 0)) {
            navigationNumber++;
            this.cachedRoute.push(array.join('/').toString());
            this.history.push({
              route: array.join('/').toString(),
              last: array.join('/').toString() === $n.join('/').toString(),
            });
            if (this.router.url !== '/' + array.join('/').toString()) {
              has_navigated = true;
              this.router.navigate([array.join('/').toString()]);
            }
          }
          i++;
        }
      }
    } catch (e) {
      this.router.navigateByUrl('/error', {
        state: { custom_error: e.toString() },
      });
    } finally {
      this.router.events.forEach((event) => {
        if (event instanceof NavigationEnd) {
          this.isNavigating().next(false);
        }
      });
      if (navigationNumber === 0 || !has_navigated) {
        this.isNavigating().next(false);
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  specialCase(route: string) {
    // do any specific route treatment;
  }

  isNavigating(): BehaviorSubject<boolean> {
    return this.isNavigating$;
  }

  firstTrack() {
    const track = (checkoutStep = undefined, checkoutType = undefined, checkoutStepRank = undefined) => {
      window.dataLayer = window.dataLayer || [];
      const pageType = window.location.hostname.includes('shop') ? 'checkout' : 'account';
      const currency = window['CONST_CURRENCY']?.id === 1 ? 'chf' : 'euro';
      const lang = window['CONST_LANG']?.shortcode;
      window.dataLayer.push({
        page_type: pageType, // 'checkout' sur shop et 'account' sur welcome
        checkout_step: checkoutStepRank, // valeur en fonction de l'étape du checkout => 'identification', si hors du checkout => undefined
        checkout_type: checkoutType,
        language: lang, // code de langue ISO
        currency: currency, // code de currency ISO
        user_id: window['CONST_USER']?.id, // user_id, si le client est loggé, si non-loggé => undefined
        login_state: window['CONST_USER']?.id ? 1 : 0, // 0 ou 1
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'viewed_checkout_step',
        checkout_step: checkoutStepRank,
      });
    };
    if (this.winState.includes('order')) {
      this.actions.pipe(ofActionCompleted(InitCart), take(1)).subscribe((e) => {
        const state = this.store.selectSnapshot(CartState)?.cart?.navigation;
        if (!state?.workflow) {
          return track();
        }
        const currentStep = state.workflow.find((x) => x.order === state.current_step);
        const checkoutStep = currentStep.name;
        const checkoutType = currentStep.type;
        const checkoutStepRank = currentStep.order;
        track(checkoutStep, checkoutType, checkoutStepRank);
      });
    } else {
      track();
    }
  }
}
