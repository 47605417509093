    <mat-dialog-content>
        <section>
            <ik-alert [description]="('Les frais de votre banque lies au virement ne doivent pas etre imputes a Infomaniak.' | transloco)"
                      [type]="'danger'"></ik-alert>
            <div>
                <p class="mat-dialog-content__message">{{'Votre commande sera traitee des reception de votre paiement. Ce processus peut prendre jusqu\'a 10 jours ouvrables.' | transloco}}</p>
                <p class="mat-dialog-content__message">{{'Souhaitez-vous quand meme utiliser ce mode de paiement ?' | transloco}}</p>
            </div>
        </section>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="block-buttons clearfix">
            <button type="submit"
                    mat-flat-button
                    color="primary"
                    (click)="save()"
                    *ngIf="!loading">{{ 'CONTINUER' | transloco }}</button><button type="submit"
                    mat-flat-button
                    color="primary"
                    *ngIf="loading">
                <div class="loader__anim bg-blue"></div>
                <div class="loader__anim bg-blue"></div>
                <div class="loader__anim bg-blue"></div>
            </button>
            <button matDialogClose
                    mat-flat-button
                    color="accent"
                    (click)="close()"
                    [disabled]="loading">{{ 'CHOISIR UN AUTRE MODE DE PAIEMENT' | transloco }}</button>
        </div>
    </mat-dialog-actions>
    <ng-template #buttonLoader>
    </ng-template>
