import { Component, Inject, OnInit, NgZone } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'switch-dialog',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss']
})

export class SwitchDialogComponent {
    account = null;
    constructor(
        private dialogRef: MatDialogRef<SwitchDialogComponent>,
    private ngZone: NgZone,
        @Inject(MAT_DIALOG_DATA) data: any) {
        this.account = window['CURRENT_ACCOUNT'] ? window['CURRENT_ACCOUNT'].organization_name : '';
    }

    save() {
        this.ngZone.run(() => {
            this.dialogRef.close('true');
         });
    }

    close() {
        this.ngZone.run(() => {
            this.dialogRef.close('false');
         });
    }

}
