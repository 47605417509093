import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-helpdesk-trigger',
  templateUrl: './helpdesk-trigger.component.html',
  styleUrls: ['./helpdesk-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpdeskTriggerComponent {
  openHelpesk(): void {
    document.dispatchEvent(new CustomEvent('openSupportModule'));
  }
}
