// `.env.ts` is generated by the `npm run env` command
import env from './.env';

export const environment = {
  env: 'prod',
  production: true,
  version: env.npm_package_version,
  serverUrl: '',
  defaultLanguage: window['CONST_LANG'].shortcode,
  supportedLanguages: window['LANGUAGES'],
  matomoUrl: 'https://analytics.infomaniak.com',
  matomoAppId: 14,
};
