<div class="tax--selector flex"
     [ngClass]="{'mobile': isMobile, 'disabled': config.disabled}">
    <span class="tax--selector--container">
        <span id="priceText" class="tax--selector--priceText">{{ 'Prix' | transloco }}</span>
        <input type="checkbox"
               id="priceTaxPreference"
               [checked]="inclVat === 'true'"
               (click)="toggleTaxMode()"
               [disabled]="config.disabled"
               class="tax--selector--priceTaxPreference tax--selector--checkboxTax"
               />
        <label for="priceTaxPreference" id="priceTaxPreferenceLabel" class="tax--selector--toggleTax"
               matTooltip="{{ config.tooltip }}"
               [matTooltipPosition]="'below'"
               aria-label="Button that displays a tooltip that hides when scrolled out of the container">
            <span class="tax--selector--toggleBgTaxTextHT"><span>{{ 'HT' | transloco }}</span></span><span class="tax--selector--toggleBgTaxTextTTC"><span>{{ 'TTC' | transloco }}</span></span>
            <span id="toggleTaxText" class="tax--selector--toggleTaxText">{{ ((inclVat === 'true') ? 'TTC' : 'HT') | transloco }}</span>
        </label>
    </span>
</div>
