import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, defer, Observable, throwError} from "rxjs";
import {isNullish} from "@infomaniak/ngx-helpers";
import {catchError, switchMap, tap} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";


export type HttpCallWithReCaptchaResponse<T, V> = ((newPayload: PayloadWithCaptchaResponse<V>) => Observable<T>);
export type PayloadWithCaptchaResponse<T> = T & {'g-recaptcha-response'?: string, captcha_visual_challenge?: boolean};


@Injectable({
  providedIn: 'root'
})
export class CaptchaHandlingService {

  readonly GRECAPTCHA_WRAPPER_ID: string = 'grecaptcha-wrapper';
  readonly RECAPTCHA_V3_PUBLIC_KEY: string = '6LdvaQAiAAAAAGNE74_HIyP2z2VlIPleqZHqZT9U';
  readonly RECAPTCHA_V2_PUBLIC_KEY: string = '6LdlcRQTAAAAACrAsSMxFTRz4UNJorgVK09t_K8_';

  #visualChallengeHasBeenTriggered = false;
  #captchaVisualChallengeId: number = null;


  visualChallengeDisplayed$ = new BehaviorSubject<boolean>(false);
  visualChallengeResponse = '';


  constructor(
    protected ngZone: NgZone,
  ) {}

  /**
   * Injects the captcha response and parameters into the given payload
   * then, it executes the callback with the modified payload
   *
   * @param httpCall Callback with the modified payload
   * @param payload Original payload
   * @param handleVisualChallenge Flag to accept the use of the visual challenge
   */
  protectWithCaptcha<T, V>(httpCall: HttpCallWithReCaptchaResponse<T, V>, payload: V, handleVisualChallenge = false): Observable<T> {
    const grecaptcha = window['grecaptcha'];
    payload = typeof payload === 'string' ? JSON.parse(payload) : payload;

    if (typeof grecaptcha === 'undefined') {
      const newPayload = {
        ...payload,
      };
      return httpCall(newPayload);
    }

    if(handleVisualChallenge && this.#visualChallengeHasBeenTriggered) {
      return this.#handleVisualCaptcha(httpCall, payload);
    }

    return this.#handleInvisibleCaptcha(httpCall, payload, grecaptcha);
  }

  displayVisualChallenge() {
    if(this.#detectIfVisualChallengeAlreadyDisplayed()) {
      return;
    }

    this.visualChallengeDisplayed$.next(true);

    if(this.#captchaVisualChallengeId !== null) {
      return;
    }

    const grecaptcha = window['grecaptcha'];
    this.#captchaVisualChallengeId = grecaptcha.render(this.GRECAPTCHA_WRAPPER_ID, {
      'sitekey': this.RECAPTCHA_V2_PUBLIC_KEY,
      'callback': (response)=> {this.#visualChallengeHasBeenClicked(response)},
    });
  }


  #handleInvisibleCaptcha<T, V>(httpCall: HttpCallWithReCaptchaResponse<T, V>, payload: V, grecaptcha: any): Observable<T> {
    return defer(() => {
      return new Promise<string>((resolve, reject) => {
        grecaptcha.ready(()  => {
          grecaptcha.execute(this.RECAPTCHA_V3_PUBLIC_KEY).then(token => {
            resolve(token);
          }).catch(() => reject(null));
        })
      });
    }).pipe(
      switchMap((captchaResponse) => {
        const newPayload = {
          ...payload,
        };

        if(captchaResponse) {
          newPayload['g-recaptcha-response'] = captchaResponse;
        }

        return httpCall(newPayload);
    }), catchError((err: HttpErrorResponse) => {
      if (err.status === 403) {
        this.displayVisualChallenge();
        this.#visualChallengeHasBeenTriggered = true;
      }
      throw err;
    }));
  }


  #handleVisualCaptcha<T, V>(httpCall: HttpCallWithReCaptchaResponse<T, V>, payload: V): Observable<T> {
    const captchaResponse = this.visualChallengeResponse;

    if(!captchaResponse) {
      // Emulate an api error response
      const errorCode = 'Vous devez compléter le challenge visuel';
      return throwError({error: { code: errorCode }});
    }

    const newPayload = {
      ...payload,
      'g-recaptcha-response': captchaResponse,
      captcha_visual_challenge: true,
    }

    return httpCall(newPayload)
      .pipe(
        catchError((error) => {
          this.#resetVisualChallenge();
          this.displayVisualChallenge();
          throw error;
        }),
        tap(() => {
          this.#resetVisualChallenge();
          this.#visualChallengeHasBeenTriggered = false;
          this.visualChallengeResponse = '';
        })
      );
  }

  #detectIfVisualChallengeAlreadyDisplayed(): boolean {
    if(!this.#visualChallengeWrapperPresent() || !this.visualChallengeDisplayed$.getValue()) {
      return false;
    }

    const wrapper = document.querySelector('#' + this.GRECAPTCHA_WRAPPER_ID);

    return wrapper.hasChildNodes();
  }

  #visualChallengeWrapperPresent(): boolean {
    return !isNullish(document.querySelector('#' + this.GRECAPTCHA_WRAPPER_ID));
  }

  #visualChallengeHasBeenClicked(response: string): void {
    // We force it to run in zone because it was called from the captcha callback
    // which is outside of NgZone (yaaayyy)
    this.ngZone.run(() => {
      this.visualChallengeDisplayed$.next(false);

      this.visualChallengeResponse = response;
    })
  }

  #resetVisualChallenge(): void {
    const grecaptcha = window['grecaptcha'];
    grecaptcha.reset(this.#captchaVisualChallengeId);
  }
}
