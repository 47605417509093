<div>
    <mat-dialog-content>
        <p class="mat-dialog-content__message" *ngIf="!isChangeOffer()" >
            {{'Souhaitez-vous vraiment supprimer ce produit $type$ de votre panier ?' | transloco:{type: (this.type|transloco)} }}
        </p>
        <p class="mat-dialog-content__message" *ngIf="isChangeOffer()" >
            {{'Souhaitez-vous vraiment annuler la modification de l\'offre $name$' | transloco:{name: this.name} }}
        </p>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="block-buttons clearfix">
            <button matDialogClose type="submit" mat-flat-button color="warn" (click)="save()">{{ 'SUPPRIMER' | transloco }}</button>
            <button matDialogClose mat-flat-button color="accent" (click)="close()">{{ 'ANNULER' | transloco }}</button>
        </div>
    </mat-dialog-actions>
</div>
