import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

export interface ScrollTarget {
    id?: string;
    className?: string;
    htmlElement?: HTMLElement;
    elementRef?: ElementRef;
    waitForLoader?: Boolean | Observable<boolean> | Promise<boolean>; // we will scroll when given value is false
    position?: ScrollLogicalPosition;
    behavior?: ScrollBehavior;
    retryUntilTargetExists?: boolean; // enable "async"
}
@Injectable()
export class ScrollService {
    // scrollToTargets: ScrollTarget[] = [];
    protected sub: Subscription | Promise<void>;

    protected target: ScrollTarget = null;

    protected isPhone = false;
    constructor(private breakpointObserver: BreakpointObserver) {
        breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium
        ]).subscribe(result => {
            if (result.breakpoints[Breakpoints.XSmall]) {
                // we may need to add || result.breakpoints[Breakpoints.Small]
                this.isPhone = true;
            } else {
                this.isPhone = false;
            }
        });
    }

    scrollToTop() {
        document.body.scrollTo({ top: 0, behavior: 'smooth' });
    }

    scrollTo(target: ScrollTarget, targetPosition: ScrollLogicalPosition = 'center') {
        if (!target) {
            console.error('no target given, cant scrollTo', target);
            return;
        }

        const tryAgain = () => {
            setTimeout(() => {
                this.scrollTo(target);
            }, 300);
        };

        // first, check when to scroll
        if (target.waitForLoader) {
            if (typeof target.waitForLoader === 'boolean') {
                tryAgain();
                return;
            }
            if (target.waitForLoader instanceof Observable || target.waitForLoader instanceof Promise) {
                const handler = target.waitForLoader instanceof Observable ? 'subscribe' : 'then';
                this.target = target;
                const sub = this.target.waitForLoader[handler]((waitForLoader: boolean) => {
                    if (waitForLoader === false) {
                        this.scrollTo({ ...this.target, waitForLoader });
                        if (this.sub instanceof Subscription) {
                            this.sub.unsubscribe();
                        }
                    }
                    return;
                });
                if (target.waitForLoader instanceof Observable) {
                    this.sub = sub;
                }
                return;
            }
        }

        // we need to define scroll target
        let scrollTarget: HTMLElement = null;
        if (target.id) {
            scrollTarget = document.getElementById(target.id);
        } else if (target.className) {
            scrollTarget = document.getElementsByClassName(target.className).length > 0 && document.getElementsByClassName(target.className)[0] as HTMLElement;
        } else if (target.elementRef) {
            scrollTarget = target.elementRef.nativeElement;
        } else {
            scrollTarget = target.htmlElement;
        }

        if (!scrollTarget) {
            if (target.retryUntilTargetExists) {
                tryAgain();
                return;
            }
            console.error('no target found, aborting scroll', target);
            return;
        }

        target = { // fill target with default parameters, overrided by given parameters
            behavior: 'smooth',
            position: this.isPhone ? 'start' : targetPosition,
            ...target
        };
        this.scrollHandler(scrollTarget, target);
        return true;
    }

    private scrollHandler(element: HTMLElement, target: ScrollTarget) {
        try {
            const ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') < 0) {
                // safari
                throw new Error('safari doesnt support smooth');
            }
            if (element.scrollIntoView) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: target.behavior, block: target.position });
                }, 50);
            } else {
                console.log('cant find scrollIntoView on this target', target, element);
            }
        } catch {
            element.scrollIntoView(true); // fallback
        }
    }
}

