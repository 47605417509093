import { Component, Inject, NgZone } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatomoTrackerService } from '@app/core/matomo-tracker.service';

@Component({
    selector: 'delete-dialog',
    templateUrl: './delete.component.html',
    styleUrls: ['./delete.component.scss']
})

export class DeleteDialogComponent {
    type: string;
    name: string;

    constructor(
        private dialogRef: MatDialogRef<DeleteDialogComponent>,
        private ngZone: NgZone,
        @Inject(MAT_DIALOG_DATA) data,
        private matomoTracker: MatomoTrackerService,
    ) {
            //console.warn(data);
        this.type = data.type;
        this.name = data.name;

    }

    save() {
        this.matomoTracker.trackClick('button_confirm-delete-cart-item');
        this.ngZone.run(() => {
            this.dialogRef.close('true');
        });
    }

    close() {
        this.ngZone.run(() => {
            this.dialogRef.close('false');
        });
    }

    isChangeOffer() {
        return this.type.indexOf('change_offer') >= 0;
    }

}
