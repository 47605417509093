import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

/**
 * Extends HttpClient with per request configuration using dynamic interceptors.
 */
@Injectable()
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;

  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}

@Injectable()
export class ShopInterceptor implements HttpInterceptor {
  debugCaptcha = false;

  constructor(private router: Router,
              private status: HTTPStatus,
              private ngZone: NgZone) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    this.status.setHttpStatus(true);

    const grecaptcha = window['grecaptcha'];
    if (this.debugCaptcha && request.body) {
      console.warn(request.url, request.body, request.params);
    }
    if (['PUT', 'POST', 'DELETE'].includes(request.method) && typeof grecaptcha !== 'undefined' && (!request.body || !request.body['g-recaptcha-response'])) {
      return new Observable<any>((observer) => {

        /**
         * We need to wait a minimum time to avoid a bad captcha result if the view was too recently loaded.
         * This is to avoid fails when using automated actions like click on a link who change view, etc.
         */
        const minSecondToWaitBeforeCaptcha = 5;
        const msToWaitBeforeCurrentCaptcha = ((Math.round(Date.now() / 1000) - window['CURRENT_TS']) < minSecondToWaitBeforeCaptcha) ? minSecondToWaitBeforeCaptcha * 1000 : 0;
        setTimeout(() => {
          grecaptcha.ready(function () {
            grecaptcha.execute('6LdvaQAiAAAAAGNE74_HIyP2z2VlIPleqZHqZT9U').then(token => {
              this.ngZone.run(() => {
                observer.next(token);
                observer.complete();
              });
            });
          }.bind(this));
        }, msToWaitBeforeCurrentCaptcha);

      }).pipe(switchMap(token => {

        /**
         * Handle captcha
         * We will try to add token at the same place as the request data
         */

        const requestBodyIsUsed = (request.body !== null && request.headers.get('Content-Type') !== 'application/x-www-form-urlencoded');
        const requestParamsIsUsed = (request.params.toString() !== '');

        if (requestBodyIsUsed) { // add the captcha token in the body
          const newBody = (typeof request.body === 'object') ? request.body : JSON.parse(request.body);
          newBody['g-recaptcha-response'] = token;
          request = request.clone({
            body: newBody,
          });
        } else if (requestParamsIsUsed) { // add the captcha token in the params
          request.params['g-recaptcha-response'] = token;
        } else { // add the captcha token in the path
          request = request.clone({
            params: request.params.set(
              'g-recaptcha-response',
              token
            )
          });
        }

        if (this.debugCaptcha) {
          console.warn('returning', request.url, request.params['g-recaptcha-response']);
        }

        return this.handleRequest(request, next);
      }));
    } else {
      return this.handleRequest(request, next);
    }
  }

  handleRequest(request, next) {
    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({
        url: environment.serverUrl + request.url
      });
    }

    if (request.url.indexOf('login') < 0) {
      request = request.clone({
        headers: request.headers.set(
          'Content-Type',
          'application/json; charset=UTF-8'
        )
      });
    }
    if (request.url.indexOf('json') === -1) {
      request = request.clone({
        headers: request.headers.set('X-Requested-With', 'XMLHttpRequest')
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });

    if (this.debugCaptcha) {
      console.warn('returning request', request);
    }

    return next.handle(request).pipe(
      tap((e) => {
        console.log(e);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(JSON.stringify(error));
        let data = {};
        data = {
          reason:
            error && error.error && error.error.reason
              ? error.error.reason
              : '',
          status: error.status || ''
        };

        if(request.params?.custom?.skipRedirect) {
          return throwError(error);
        }

        if ((error.status === 400 || error.status === 500) && !window['hadError']) {
          if (!/(payment\/card)/i.test(request.url) && !request.url.includes('/confirm_phone_code')) {
            if (environment.production) {
              window.location.assign('/?#hadError');
            } else {
              console.log('::::The page should be refreshed in production::::');
            }
          }
        }

        if (environment.env === 'prod' && error.status === 500 && !/(payment\/card)/i.test(request.url)) {
          this.router.navigateByUrl('/error', {
            state: { custom_error: JSON.stringify(data) }
          });
        }

        if (error.status === 403) {
          this.router.navigateByUrl('/error', {
            state: { custom_error: JSON.stringify(data) }
          });
        }
        return throwError(error);
      }),
      finalize(() => {
        this.status.setHttpStatus(false);
      }),
    );
  }
}


