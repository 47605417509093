import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  /**
   * Helper pour tests sur les breakpoints
   * @param type
   */
  responsiveHelper(type) {
    // windows property
    let documentHeight = document.documentElement.clientHeight || 0;
    let documentWidth = document.documentElement.clientWidth || 0;
    let windowHeight = window.innerHeight || 0;
    let windowWidth = window.innerWidth || 0;

    let breakpoints = {
      // Extra small screen / phone
      xs: 0,
      // Medium screen / tablet
      sm: 768,
      // Large screen / desktop
      md: 992,
      // Extra large screen
      lg: 1366,
      // Wide desktop
      xl: 1600,
    };

    switch (type) {
      case 'sm':
        return windowWidth >= breakpoints.sm;
      case 'md':
        return windowWidth >= breakpoints.md;
      case 'lg':
        return windowWidth >= breakpoints.lg;
      case 'xl':
        return windowWidth >= breakpoints.xl;
    }
  }
}
