import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

declare let window: {
  [key: string]: any;
  prototype: Window;
  new (): Window;
};

type IMatomoEventCategory = 'Click' | 'Test';

@Injectable({
  providedIn: 'root',
})
export class MatomoTrackerService {
  isInitialized = false;
  latestPageView = '';

  constructor(private matDialogService: MatDialog) {
    window._paq = window._paq || [];
  }

  init(url: string, siteId: number): void {
    const userId =
      window['CONST_USER'] && window['CONST_USER'].iGlobalUserCode ? window['CONST_USER'].iGlobalUserCode : null;
    if (userId) {
      window._paq.push(['setUserId', String(userId)]);
    }
    window._paq.push(['trackPageView']);
    window._paq.push(['enableLinkTracking']);

    (() => {
      // add trailing slash if needed
      const u = url.endsWith('/') ? url : `${url}/`;

      // configure the tracker
      window._paq.push(['setTrackerUrl', `${u}matomo.php`]);
      window._paq.push(['setSiteId', siteId]);

      // insert the matomo script
      const d = document;
      const g = d.createElement('script');
      const s = d.getElementsByTagName('script')[0];

      g.type = 'text/javascript';
      g.async = true;
      g.defer = true;
      g.src = `${u}matomo.js`;
      s.parentNode.insertBefore(g, s);

      this.isInitialized = true;
    })();
  }

  trackClick(value: string): void {
    this.trackEvent('Click', value);
  }

  trackPageView(): void {
    if (window.location.href !== this.latestPageView) {
      window._paq.push(['setReferrerUrl', window.location.href]);
      window._paq.push(['setCustomUrl', window.location.pathname]);
      window._paq.push(['trackPageView']);
      this.latestPageView = window.location.href;
    }
  }

  private trackEvent(category: IMatomoEventCategory, action?: string, value?: string | boolean) {
    if (action && value) {
      window._paq.push(['trackEvent', category, action, value]);
    } else if (action) {
      window._paq.push(['trackEvent', category, action]);
    } else {
      window._paq.push(['trackEvent', category]);
    }
  }
}
