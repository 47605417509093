import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cannot-checkout',
  templateUrl: './cannot-checkout.component.html',
  styleUrls: ['./cannot-checkout.component.scss']
})
export class CannotCheckoutComponent {

  clientName: string;
  ppErrorDetails: string;

  constructor(private dialogRef: MatDialogRef<CannotCheckoutComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private ngZone: NgZone) {
    this.clientName = data.clientName;
    this.ppErrorDetails = data.ppErrorDetails;
  }

  checkout(): void {
    console.log('checkout');
    this.ngZone.run(() => {
      this.dialogRef.close('true');
    });
  }

  close(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(false);
    });
  }

}
