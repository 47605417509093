import { Injectable } from '@angular/core';
import { Store } from './store';
import { UserState } from './user.state';

@Injectable()
export class UserService extends Store<UserState> {
    constructor() {
        super(new UserState());
    }

    addProduct(name: string, id: number): void {
        this.setState({
            ...this.state,
            products: [...this.state.products, { name: name, id: id }]
        });
    }

    deleteProduct(product_id: number): void {
        this.setState({
            ...this.state,
            products: this.state.products.filter(
                (item, index) => item.id !== product_id
            )
        });
    }
}
